function Experience() {
  return (
    <div className="pl-10 md:pl-0 row-span-5 col-span-5 overflow-auto scrollbar-thumb-green scrollbar-thin scrollbar-thumb-rounded-md">
      <div className="flex flex-col gap-10 justify-center h-full">
        <div className="flex-shrink w-11/12 md:w-9/12">
          <div id="fontLigBold" className="text-xl border-b-2 border-whiteGreen2 mb-3">Fullstack Developer</div>
          <div id="fontLigBold" className="text-5xl text-whiteGreen">GREENEST AI</div>
          <div id="fontLigBold" className="text-2xl text-green3 mb-3">Spain, Madrid, Madrid</div>
          <div id="fontLigBold" className="text-whiteGreen2">
            <p><font className="text-whiteGreen">DevOps</font> experience with <font className="text-whiteGreen">GitLab CI/CD</font> environment.</p>
            <p><font className="text-whiteGreen">Frontend</font> development with <font className="text-whiteGreen">Next.js</font> and <font className="text-whiteGreen">.Net 8</font> and <font className="text-whiteGreen">Golang</font> as the main <font className="text-whiteGreen">Backend</font> languages</p>
            <p><font className="text-whiteGreen">AI</font> use for data analisys and chatbots</p>
          </div>
        </div>
        <div className="flex-shrink w-11/12 md:w-9/12">
          <div id="fontLigBold" className="text-xl border-b-2 border-whiteGreen2 mb-3">Backend Software Developer</div>
          <div id="fontLigBold" className="text-5xl text-whiteGreen">SERBATIC</div>
          <div id="fontLigBold" className="text-2xl text-green3 mb-3">Spain, Castille and Leon, Zamora</div>
          <div id="fontLigBold" className="text-whiteGreen2">
            <font className="text-whiteGreen">Java</font> experience with <font className="text-whiteGreen">Hibernate</font>, <font className="text-whiteGreen">Spring</font> and <font className="text-whiteGreen">API REST/SOAP. <br/>Middleware </font>
            experience with <font className="text-whiteGreen">OSB</font> and <font className="text-whiteGreen">BPEL<br/>Agile development</font> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience;

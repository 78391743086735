import { Link } from "react-router-dom";

function ListMenu({ number, name, url, current }) {
  return (
    <li className="nav-item" id={(current ? "fontLigBold" : "")}>
      <Link
        className="px-3 py-2 flex items-center text-white hover:opacity-75"
        to={url}
      >
        <font className="text-cyan select-none">{number}</font><font className='select-none ml-2'>{name}</font>
      </Link>
    </li>
  );
}

export default ListMenu;
